import { render, staticRenderFns } from "./iletisim.vue?vue&type=template&id=f2dc4cf8&"
import script from "./iletisim.vue?vue&type=script&lang=js&"
export * from "./iletisim.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports